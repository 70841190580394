@charset "utf-8";


/*-------------------------------------
共通
--------------------------------------*/
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

html {
    width: 100%;
    height: 100%;
    font-size: 62.5%
}

body {
    color: #333;
    background: #fff;
    height: 100%;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6;
    overflow-x: hidden;
    
    -moz-font-feature-settings: palt;
    -webkit-font-feature-settings: palt;
    font-feature-settings: palt;
    -webkit-overflow-scrolling: touch;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    background: url(../images/bg.jpg) top center repeat;
    letter-spacing: 1px;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0
}

p {
    margin-bottom: 0;
    font-size: 14px;
    font-size: 1.4rem;
}

a {
    text-decoration: none !important
}

ul {
    padding: 0;

}

ul li {
    list-style: none;
}

small {
    font-size: 11px;
    font-size: 1.1rem;
}

h2{    line-height: 1;}

.img-responsive {
    max-width: 200px;
    max-height: 200px;
}

.error{
    color: #eb3b5a;
    font-weight: bold;
    font-size: 12px;
    font-size: 1.2rem;
}
/*-- loading bar --*/
.loadingBar {
    width: 0;
    height: 3px;
    background: linear-gradient(to right, #4b6584, #4b6584);
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.2s linear 0s;
    z-index: 100;
}
/*-------------------------------------
ボタン
--------------------------------------*/
.button-wrapper {
    text-align: center;
}
.button-entry,
.button {
    display: block;
    max-width: 260px;
    padding: 14px 0;
    background: #fc5c65;
    border: 1px solid #fc5c65;
    border-radius: 4px;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    display: block;
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #fff;
    font-weight: bold;
    margin-top: 12px;
    outline: none;
    cursor: pointer;
}
.button-entry:hover,
.button:hover{
    color: #fc5c65 !important;
    background-color: transparent;
    text-decoration: none;
}
/*
.button-entry:before,
.button:before {
    content: "";
    position: absolute;
    bottom: -40px;
    right: -40px;
    width: 10px;
    height: 10px;
    background: #222;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}


.button span{
	
}
.button:hover,
.button:focus {
    color: #fff !important;
}

.button:hover:before,
.button:focus:before {
    width: 400px;
    height: 400px;
    opacity: 1;
}

.button:hover:after {
    color: #fff !important;
    background-color: transparent;
    text-decoration: none;
}
.button-entry:after,
.button:after {
    font-size: 12px;
    position: absolute;
    top: 17px;
    right: 20px;
    color: #fff;
    font-weight: bold;
}
.button-entry:after,
.button:after {
    font-family: FontAwesome;
    content: "\f105";
}
*/
.button-twitter {
    padding: 6px 0;
    background: #50abf1;
    border: 1px solid #50abf1;
    border-radius: 4px;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: bold;
    display: block;
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #fff;
    font-weight: bold;
    margin-top: 12px;
}

.button-twitter:hover {
    color: #50abf1;
    background: #fff;
    border: 1px solid #50abf1;
}
.button-entry{
	background-color: #20bf6b;
	color: #eb3b5a;
	    border-bottom: 4px solid #0b8c48;
}

.button-entry:hover{
	background-color: #4b6584;
	border-bottom: 4px solid #4b6584;
}
.button-entry:hover:after,
.button-entry:hover,
.button-entry:focus {
    color: white !important;
}

.button-entry:after{

}



/*-------------------------------------
レスポンシブ
--------------------------------------*/

@media (min-width:992px) {
    .container {
        width: 100%;
        max-width: 720px;
    }
    .container li {
      margin: 5px 0px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 100%;
        max-width: 720px;
    }
    .container li {
      margin: 5px 0px;
    }
}

@media (max-width: 767px) {

    .col-xxs-1,
    .col-xxs-2,
    .col-xxs-3,
    .col-xxs-4,
    .col-xxs-5,
    .col-xxs-6,
    .col-xxs-7,
    .col-xxs-8,
    .col-xxs-9,
    .col-xxs-10,
    .col-xxs-11,
    .col-xxs-12 {
        min-height: 1px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 480px) {

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
        float: none;
        width: auto
    }
    .col-xs-13 {
        float: none;
        width: auto
    }
    .col-lg-offset-1,
    .col-lg-offset-2,
    .col-lg-offset-3,
    .col-lg-offset-4,
    .col-lg-offset-5,
    .col-lg-offset-6,
    .col-lg-offset-7,
    .col-lg-offset-8,
    .col-lg-offset-9,
    .col-lg-offset-10,
    .col-lg-offset-11,
    .col-lg-offset-12,
    .col-md-offset-1,
    .col-md-offset-2,
    .col-md-offset-3,
    .col-md-offset-4,
    .col-md-offset-5,
    .col-md-offset-6,
    .col-md-offset-7,
    .col-md-offset-8,
    .col-md-offset-9,
    .col-md-offset-10,
    .col-md-offset-11,
    .col-md-offset-12,
    .col-sm-offset-1,
    .col-sm-offset-2,
    .col-sm-offset-3,
    .col-sm-offset-4,
    .col-sm-offset-5,
    .col-sm-offset-6,
    .col-sm-offset-7,
    .col-sm-offset-8,
    .col-sm-offset-9,
    .col-sm-offset-10,
    .col-sm-offset-11,
    .col-sm-offset-12,
    .col-xs-offset-1,
    .col-xs-offset-2,
    .col-xs-offset-3,
    .col-xs-offset-4,
    .col-xs-offset-5,
    .col-xs-offset-6,
    .col-xs-offset-7,
    .col-xs-offset-8,
    .col-xs-offset-9,
    .col-xs-offset-10,
    .col-xs-offset-11,
    .col-xs-offset-12 {
        margin: 0
    }

    .col-xxs-1,
    .col-xxs-2,
    .col-xxs-3,
    .col-xxs-4,
    .col-xxs-5,
    .col-xxs-6,
    .col-xxs-7,
    .col-xxs-8,
    .col-xxs-9,
    .col-xxs-10,
    .col-xxs-11,
    .col-xxs-12 {
        float: left
    }

    .col-xxs-12 {
        width: 100%
    }

    .col-xxs-11 {
        width: 91.66666667%
    }

    .col-xxs-10 {
        width: 83.33333333%
    }

    .col-xxs-9 {
        width: 75%
    }

    .col-xxs-8 {
        width: 66.66666667%
    }

    .col-xxs-7 {
        width: 58.33333333%
    }

    .col-xxs-6 {
        width: 50%
    }

    .col-xxs-5 {
        width: 41.66666667%
    }

    .col-xxs-4 {
        width: 33.33333333%
    }

    .col-xxs-3 {
        width: 25%
    }

    .col-xxs-2 {
        width: 16.66666667%
    }

    .col-xxs-1 {
        width: 8.33333333%
    }

    .col-xxs-pull-12 {
        right: 100%
    }

    .col-xxs-pull-11 {
        right: 91.66666667%
    }

    .col-xxs-pull-10 {
        right: 83.33333333%
    }

    .col-xxs-pull-9 {
        right: 75%
    }

    .col-xxs-pull-8 {
        right: 66.66666667%
    }

    .col-xxs-pull-7 {
        right: 58.33333333%
    }

    .col-xxs-pull-6 {
        right: 50%
    }

    .col-xxs-pull-5 {
        right: 41.66666667%
    }

    .col-xxs-pull-4 {
        right: 33.33333333%
    }

    .col-xxs-pull-3 {
        right: 25%
    }

    .col-xxs-pull-2 {
        right: 16.66666667%
    }

    .col-xxs-pull-1 {
        right: 8.33333333%
    }

    .col-xxs-pull-0 {
        right: auto
    }

    .col-xxs-push-12 {
        left: 100%
    }

    .col-xxs-push-11 {
        left: 91.66666667%
    }

    .col-xxs-push-10 {
        left: 83.33333333%
    }

    .col-xxs-push-9 {
        left: 75%
    }

    .col-xxs-push-8 {
        left: 66.66666667%
    }

    .col-xxs-push-7 {
        left: 58.33333333%
    }

    .col-xxs-push-6 {
        left: 50%
    }

    .col-xxs-push-5 {
        left: 41.66666667%
    }

    .col-xxs-push-4 {
        left: 33.33333333%
    }

    .col-xxs-push-3 {
        left: 25%
    }

    .col-xxs-push-2 {
        left: 16.66666667%
    }

    .col-xxs-push-1 {
        left: 8.33333333%
    }

    .col-xxs-push-0 {
        left: auto
    }

    .col-xxs-offset-12 {
        margin-left: 100%
    }

    .col-xxs-offset-11 {
        margin-left: 91.66666667%
    }

    .col-xxs-offset-10 {
        margin-left: 83.33333333%
    }

    .col-xxs-offset-9 {
        margin-left: 75%
    }

    .col-xxs-offset-8 {
        margin-left: 66.66666667%
    }

    .col-xxs-offset-7 {
        margin-left: 58.33333333%
    }

    .col-xxs-offset-6 {
        margin-left: 50%
    }

    .col-xxs-offset-5 {
        margin-left: 41.66666667%
    }

    .col-xxs-offset-4 {
        margin-left: 33.33333333%
    }

    .col-xxs-offset-3 {
        margin-left: 25%
    }

    .col-xxs-offset-2 {
        margin-left: 16.66666667%
    }

    .col-xxs-offset-1 {
        margin-left: 8.33333333%
    }

    .col-xxs-offset-0 {
        margin-left: 0
    }
}


/*-------------------------------------
ページ
--------------------------------------*/
header {
    background: #fff;
    padding: 15px 0;
}


article .content {
    

    /* background-size: cover; */

    overflow: hidden;
}

.title h2 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    font-size: 2.4rem;
    color: #4b6584;
}

.title span {
    text-transform: uppercase;
    font-size: 18px;
    color: #4b6584;
}
.title i{
    font-size: 48px;
    font-size: 4.8rem;
        color: #4b6584;
}


#page1 {
    
    overflow: hidden;
}

#page1 h2,
#page1 h3 {
    font-weight: bold;
    color: #4b6584;
}

#page1 h3 {
    font-style: 16px;
    font-size: 1.6rem;
}

#page1 .col-xs-12 {
    
    overflow: hidden;
    float: none;
    margin: 0px auto;
    padding-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

#page1 .col-xs-12 img {
    /*width: 100%;*/
    max-width: 100%;
    margin-bottom: 40px;
}

#page2 .title {
    padding-top: 30px;
    padding-bottom: 30px;
}

#page2 {
    
    background: #d1d8e0;
    overflow: hidden;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 60px;
    color: #fff;
}
#page2.atari{
	padding: 0 0 0 0;
    background-image: url(../images/bg-atari.jpg);
    background-repeat: repeat;
    background-size: contain;
    padding: 30px 0;
}
#page2.atari .ttl h2{
    color:#eb3b5a;
    font-size: 60px;
    font-size: 6.0rem;
    font-weight: bold;
}


#page2.atari p{
        color: #4b6584;
}

#page2.atari .ttl p{
    color:#eb3b5a;
    font-size: 30px;
    font-size: 3rem;
    font-weight: bold;
}

#page2.hazure{
    padding: 0 0 0 0;
    background-image: url(../images/bg-hazure.jpg);
    background-repeat: repeat-x;
    background-size: contain;
    padding: 30px 0;
}

#page2.hazure .ttl h2{
    color:#45aaf2;
    font-size: 60px;
    font-size: 6.0rem;
    font-weight: bold;
}

#page2.hazure .hazure-text p{
    color: #4b6584;
    /*
    text-align: left;
    font-size: 14px;
    font-text-align: center;
    */
    font-size: 16px;
    font-size: 1.6rem;

}

#page2 .step-container{
    float: none;
    margin: 0px auto;
    background: #fff;    
    color: #4b6584;
    display: flow-root;
    margin-bottom: 80px;
    padding: 10%;
    text-align: left;
}

.bg-atari{
    background-image: url(../images/bg-atari.png);
    background-repeat: repeat;
    background-size: contain;
    padding: 60px 0;
}

.atari-text{
	padding-top: 20%;
}


.atari-text h4{
	font-size: 24px;
	font-size: 2.4rem;
	font-weight: bold;
	margin-bottom: 24px;
}

.atari-text h4 span{
	font-size: 16px;
	font-style: 1.6rem;
}


.ttl{
    margin: 0 auto;
    line-height: 1;
    }
.present-name{
    padding: 20px 0;
}
.present-name p{
    font-size: 24px!important;
    font-size: 2.4rem!important;
    font-weight: bold;
    color: #000;
    white-space: pre-line;
}
.present-thum{
  display: inline-block;
  width: 200px;
  height: 200px;
  
  overflow: hidden;
  border-radius: 50%;
  margin: 30px 0px;
}
.present-thum img{
  position: absolute;
  left: -100%;
  right: -100%;
  margin: auto;
}

#page2 .step1,
#page2 .step2,
#page2 .step3 {
    float: none;
    margin: 0px auto;
    background: #fff;
    
    color: #4b6584;
    display: flow-root;
    margin-bottom: 80px;
    padding: 10%;
}

#page2 .step1:after,
#page2 .step2:after {
    content: "";
    width: 50px;
    height: 50px;
    background: url(../images/arrow-down.png) center no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -65px;
    left: 0;
    right: 0;
    margin: 0px auto;
}

#page2 h3 {
    font-size: 32px;
    font-style: 3.2rem;
    font-weight: bold;
    font-family: Arial;
    text-transform: uppercase;
    margin-bottom: 0px;
    margin: 0 0 0 0;
}

#page2 p {
    font-size: 16px;
    font-size: 1.6rem;
}

#page3 {
    
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 60px;
    text-align: center;
}

#page3 .col-xs-12 {
    float: none;
    margin: 0px auto;
    text-align: left;
    position: inherit;
    margin: 30px 0px;
}

#page3 .col-xs-12 img {
    max-width: 100%;
}

#page3 .col-xs-13 {
    float: none;
    margin: 0px auto;
    text-align: center;
    position: inherit;
    margin: 30px 0px;
}

#page2 .step3{
    margin-bottom: 0;
}

#page2 .fa-line-friendship div{
    margin: 20px 0;
}
#page2 .fa-line-friendship img{
    margin: auto;
}
#page2 .fa-line-friendship .line-official-icon{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 0.5px solid rgba(0,0,0,.2);
}
footer {
    background: #4b6584;
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    color: #fff;
}

footer p {
    font-weight: bold;
    white-space: pre-line;
}

footer h3 {
    font-size: 16px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 0;
}

footer h4 {
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: bold;
    padding: 10px 0;
    margin: 0 0 0 0;
}

footer p span {
    font-size: 11px;
    font-size: 1.1rem;
}

.coppyright {
    text-align: center;
    color: #4b6584;
    font-family: Arial;
    padding: 15px 0;
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {

    .title h2 {
        font-size: 18px;
        font-size: 1.8rem;
    }
    #page2 .step1,
    #page2 .step2,
    #page2 .step3 {
        padding: 20px;
    }

    #page2 p {
        font-size: 14px;
        font-size: 1.4rem;
    }
}


/*-------------------------------------
フォーム
--------------------------------------*/
.form,
.enquete{
	margin-bottom: 60px;
	color: #4b6584;
}

.form h2,
.enquete h2{
    font-size: 24px;
    font-size: 2.4rem;
    padding-bottom: 10px;
    border-bottom: 1px solid #d1d8e0;
    color: #4b6584;
    padding-top: 60px;
}

.form h3,
.enquete h3,
.enquete h4{
	font-size: 18px;
	font-size: 1.8rem;
	font-weight: bold;
	color: #4b6584;
}

.enquete h4{
	font-size: 14px;
	font-size: 1.4rem;
}
.table-form{
	margin:10px 0;
}
.table-form,
.table-form td{
	border:none!important;
}
.table-form td:first-child{
	font-weight: bold;
	padding-left: 0!important;
}
.contact-block{
	margin-bottom: 30px;
}
.required{
	background-color: #eb3b5a;
	color: #fff;
	font-weight: bold;
	font-size: 10px;
	font-size: 1rem;
	padding: 4px 8px;
	    border-radius: 3px;
}
.desc{
	font-size: 11px;
	font-size: 1.1rem;
}

.zip1,
.zip2{
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
}


@media (max-width: 767px) {

    .form h2,
    .enquete h2{
        font-size: 18px;
        font-size: 1.8rem;
    }

    .table-form th,
    .table-form td {
        display: block;
    }
    .table-form td{

        padding-left: 0!important;
    }
    .table-form tr{
        display: block;
        margin-bottom: 10px;
    }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite;
}

.loading{
  cursor: progress;
}

@keyframes spinner-border {
  100% { transform: rotate(360deg); }
}

@keyframes inner-spinner-border {
  100% { transform: rotate(-360deg); }
}

.spinner-border-parent{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform : translate(-50%,-50%);
  transform : translate(-50%,-50%);
  color: #4b6584;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
}
.spinner-border {
  width: 10rem;
  height: 10rem;
  vertical-align: text-bottom;
  border: 25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  border-width: 1rem;
  text-align: center;
  margin: 10px auto;
  color: #4b6584;
}

.spinner-border-small {
  width: 5rem;
  height: 5rem;
  vertical-align: text-bottom;
  border: 25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  border-width: 1rem;
  text-align: center;
  margin: 100px auto;
  color: #4b6584;
}

.line-login{
  background-color: #00C300 !important;
  font-weight: normal !important;
  color: #FFFFFF;
  text-shadow: none;
  width: 190px;
  margin: auto;
  padding: 7px 20px 7px 55px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.line-login:hover{
  background-color: #00E000 !important;
}

.line-login:hover .icon{
    border-right: 1px solid #00c900;
}

.line-login:active{
  background-color: #00B300 !important;
}

.line-login:active .icon{
    border-right: 1px solid #009800;
}

.button-wrapper .description{
    margin-bottom: 15px;
}

.icon{
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    line-height: 1;
    border-radius: 0px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    text-align: center;
    margin: 0em;
    width: 2.57142857em;
    background-color: rgba(0,0,0,0.05);
    color: '';
    box-shadow: -1px 0px 0px 0px transparent inset;
    padding: 2px;
    border-right: 1px solid #00b300;
}

.campaign-term{
  text-align: center;
}

.campaign-code-form input{
  width: 260px;
  height: 35px;
  text-align: center;
  margin: 20px 0px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.lottery-gimmick img{
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.header-error-text{
    text-align: center;
    font-size: 1.6rem;
    color: #fc5c65 !important;
    background-color: white;
    text-decoration: none;
    border: 1px solid #fc5c65;
    font-weight: bold;
    padding: 10px 0;
}

.campaign-code-error{
    text-align: center;
    font-size: 1.6rem;
    color: #fc5c65 !important;
    text-decoration: none;
    font-weight: bold;
}